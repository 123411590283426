import React from "react";
import "./ApplyButton.css";

export default function ApplyButton({ onClick, showError }) {
  return (
    <div>
      <button
        className="btn-apply flex justify-center items-center px-6 py-3 leading-snug border border-transparent rounded-lg cursor-pointer font-semibold focus:outline-none focus:shadow-outline"
        onClick={onClick}
      >
        <span>
          <GoogleLogo />
        </span>
        <span className="pl-3">Apply With Google</span>
      </button>
      <Error showError={showError} />
    </div>
  );
}

function GoogleLogo() {
  return (
    <svg
      className="google-logo"
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="google"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 488 512"
      data-fa-i2svg=""
    >
      <path
        fill="currentColor"
        d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
      ></path>
    </svg>
  );
}

function Error({ showError }) {
  const hiddenClass = showError ? "" : "hidden";
  const classes = `${hiddenClass} text-red-700 px-4 my-1 py-1`;
  return (
    <div className={classes} role="alert">
      <p className="text-sm">We couldn't sign you in. Please try again.</p>
    </div>
  );
}
