import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import HomePage from "../Home";
import RoleDetailPage from "../RoleDetail";
import ApplicationFormPage from "../ApplicationForm";
import NotFoundPage from "../NotFound";

import Navigation from "../Navigation";
import BannerImage from "../BannerImage";
import Footer from "./Footer";

import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";

const App = () => {
  return (
    <>
      <section className="min-h-screen">
        <header>
          <div className="pl-2 pr-2 md:pl-10 md:pr-10 lg:pl-16 lg:pr-16">
            <Navigation />
          </div>
          <BannerImage />
        </header>
        <main className="pl-2 pr-2 md:pl-10 md:pr-10 lg:pl-16 lg:pr-16">
          <Router>
            <Switch>
              <Route exact path={ROUTES.HOME} component={HomePage} />
              <Route path={`/:role/apply`} component={ApplicationFormPage} />
              <Route path={`/:role`} component={RoleDetailPage} />
              <Route component={NotFoundPage} />
            </Switch>
          </Router>
        </main>
      </section>
      <Footer />
    </>
  );
};

export default withAuthentication(App);
