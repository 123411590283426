import React from "react";

import { FirebaseContext } from "../Firebase";
import { AuthUserContext } from "../Session";
import Snackbar from "../Snackbar";

const Navigation = () => {
  const firebase = React.useContext(FirebaseContext);
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const SNACKBAR_OPEN_DURATION = 3000;

  function handleSignOutClick() {
    firebase.doSignOut();
    setShowSnackbar(true);
    setTimeout(() => {
      setShowSnackbar(false);
    }, SNACKBAR_OPEN_DURATION + 10);
  }

  return (
    <nav className="flex flex-row items-center justify-between py-2">
      <a href="/" className="text-md text-xl">
        Careers @ MountBlue
      </a>
      <AuthUserContext.Consumer>
        {(authUser) =>
          authUser ? <SignOutButton onClick={handleSignOutClick} /> : <></>
        }
      </AuthUserContext.Consumer>
      {showSnackbar && (
        <Snackbar
          message={"You've been signed out"}
          duration={SNACKBAR_OPEN_DURATION}
        />
      )}
    </nav>
  );
};

const SignOutButton = ({ onClick }) => {
  return (
    <button
      className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-4 border border-blue-500 hover:border-transparent rounded"
      onClick={onClick}
    >
      Sign Out
    </button>
  );
};

export default Navigation;
