import React from "react";

export default function Post({
  title,
  role,
  location,
  salary,
  sections,
  ApplyButton,
}) {
  return (
    <section className="flex flex-col">
      <div className="-mt-20 p-8 shadow-lg rounded-lg bg-white">
        <h1 className="text-2xl sm:text-4xl font-semibold text-gray-800">
          {title}
        </h1>
        <h4 className="text-lg">{role}</h4>
        <p className="text-lg mt-4">
          Location: <span className="text-base">{location}</span>
        </p>
        <p className="text-lg">
          Salary: <span className="text-base">{salary}</span>
        </p>
        <div className="mt-4">
          <ApplyButton />
        </div>
      </div>
      <div>
        {sections.map(({ title, content, type, url }) => (
          <div key={title} className="my-2">
            <h1 className="text-xl sm:text-3xl font-semibold text-gray-800 mb-2 mt-12">
              {title}
            </h1>
            {renderSection({ type, content, url })}
          </div>
        ))}
      </div>
      <div className="mt-4">
        <ApplyButton />
      </div>
    </section>
  );
}

function renderSection({ type, content, url }) {
  switch (type) {
    case "para":
      return renderParaSection({ content });
    case "list":
      return renderListSection({ content });
    case "url":
      return renderUrlSection({ content, url });
    default:
      throw new Error(`${type} is an invalid job posting section`);
  }
}

function renderParaSection({ content }) {
  return <p className="leading-relaxed text-gray-900">{content}</p>;
}

function renderListSection({ content }) {
  return (
    <ul className="list-disc ml-8">
      {content.map((item) => (
        <li key={item} className="my-2 text-gray-900">
          {item}
        </li>
      ))}
    </ul>
  );
}

function renderUrlSection({ url, content }) {
  return (
    <ul className="list-disc ml-8">
      <li className="my-2 text-gray-900">
        <a href={url} className="text-blue-600 hover:text-blue-800 underline">
          {content}
        </a>{" "}
      </li>
    </ul>
  );
}
