import React from "react";
import { Link } from "react-router-dom";

import jobPosts from "../../data";

import "./index.css";

function HomePage() {
  const activePosts = jobPosts.filter((p) => p.active === true);

  return (
    <article className="flex justify-center my-10">
      <div>
        <h1 className="text-2xl sm:text-4xl font-semibold text-gray-800">
          Careers at MountBlue
        </h1>
        <div className="my-6">
          <ul>
            {activePosts.map((post) => {
              return (
                <li className="my-3 text-base text-lg sm:text-xl" key={post.id}>
                  <Link
                    to={`/${post.slug}`}
                    className="role-link text-blue-900"
                  >
                    <span>{post.badge}</span>
                    <span>{post.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </article>
  );
}

export default HomePage;
