const jobPosts = [
  {
    id: 1,
    badge: "🎖 ",
    slug: "sde",
    title: "Software Development Engineer",
    active: true,
    salary: "INR 400,800 per annum",
    location: "Bengaluru, India",
    role: "This is an entry-level position",
    showUrlField: false,
    yearsExpField: {
      show: false,
      start: 2,
      end: 10,
    },
    explainResumeNotNeeded: true,
    sections: [
      {
        title: "Overview",
        type: "para",
        content:
          "MountBlue is a premium services provider and is looking for smart and motivated programmers to join their team. The candidate must have programming experience in at least one of the following languages: Javascript, Java, Python, PHP, Ruby, C or C++. Selected candidates will go through an extensive 12-13 weeks training program and on successfully completing the requirements of the program will be offered the role of a Software Development Engineer",
      },
      {
        title: "Responsibilities",
        type: "list",
        content: [
          "Work in a team to develop, compile, test, debug & deploy software",
          "Develop web and mobile applications using Javascript, Python, Java, HTML and CSS",
          "Ensure close to 100% timely & defect-free software deployments",
          "Write documentation for the software and APIs",
          "Support software during production and provide hot fixes",
        ],
      },
      {
        title: "Qualifications",
        type: "list",
        content: [
          "Familiarity with at least one programming language. Example: Javascript, Java, PHP or Python",
          "Good analytical and problem solving skills",
          "Self-motivated with ability to work in a fast-paced environment",
          "Excellent verbal and written communication skills",
          "Eagerness to learn new technologies",
        ],
      },
      {
        title: "Benefits",
        type: "list",
        content: [
          "Perfect launchpad for a high flying career in technology",
          "You will get the opportunity to interact with and learn from the CTOs and Directors of Engineering of leading technology startups in India",
        ],
      },
      {
        title: "Learn More",
        type: "url",
        url: "https://www.glassdoor.co.in/Overview/Working-at-MountBlue-Technologies-EI_IE1933586.11,33.htm",
        content: "Read what others have said on Glassdoor",
      },
    ],
  },
  {
    id: 2,
    badge: "🖊 ",
    slug: "mentor",
    title: "Software Engineering Mentor",
    active: true,
    location: "Bengaluru, India",
    showUrlField: true,
    yearsExpField: {
      show: true,
      start: 2,
      end: 10,
    },
    explainResumeNotNeeded: false,
    role: "Minimum 2+ years of experience. Open for full time employment and consulting / contractor roles.",
    salary: "INR 12 LPA - INR 18 LPA",
    sections: [
      {
        title: "What is the role ?",
        type: "para",
        content: [
          "Design, plan and implement a 12-13 week intensive coding bootcamp in one of the learning paths like Full-Stack web development, Android, Frontend development and Backend development. ",
          "Design a curriculum by leveraging the best of online resources. ",
          "Help trainees get un-stuck, debug, clarify concepts. ",
          "Do code reviews and give generous feedback to trainees. ",
          "Be a coach and a cheerleader. Continuously push, nudge and encourage trainees to produce their best work. ",
          "Be fully responsible and accountable for the success of every trainee. ",
        ],
      },
      {
        title: "What is MountBlue ?",
        type: "para",
        content:
          "MountBlue is a unique coding bootcamp based out of Bangalore. Our vision is to be an alternative to traditional college education. Currently, we run coding bootcamps every 3 months for entry level developers on most popular web and mobile technologies, with the goal of finding them rewarding software development careers in startups. We already work with >150 startups. Our developers are in some of the most well-known startups in India - from bootstrapped software shops to unicorns. If successful, MountBlue will rewrite the technology higher education paradigm in the country.",
      },
      {
        title: "Requirements",
        type: "list",
        content: [
          "You love programming and have an endless list of things that you want to build",
          "You want to learn how to motivate others, improve people skills and provide guidance to aspiring developers",
          "You are looking to build subject-matter expertise and deepen your own personal knowledge",
          "You have a tech blog where you share your latest exploits",
          "You can eli5 most things you know",
          "You believe that education is broken and have ideas on how it could be made better",
          "2+ years of software development experience",
        ],
      },
      {
        title: "Benefits",
        type: "list",
        content: [
          "75 days of paid vacation! Work on side projects, incubate your startup, go do Vipassana, contribute to open source, learn the latest and the greatest or just chill",
          "Time-boxed work hours",
          "Competitive compensation",
          "INR 1L allowance that can be used for courses, seminars, events and travel",
          "Get paid to learn and teach",
          "Make an impact - Your contribution in building the next generation of coders",
          "Various CTOs and Directors of Engineering visit our bootcamp on a regular basis. Interact with them",
          "You will see and participate up close in the building of an early stage startup",
          "Great way to build people and leadership skills. Grow beyond being only an engineer- become a leader",
        ],
      },
      {
        title: "Learn more",
        type: "url",
        url: "https://www.geektrust.in/blog/2019/10/11/startups-2-0-work-mountblue/",
        content: "Read Geektrust's blog post on the role",
      },
    ],
  },
];

export function getPostByRole(slug) {
  let post = jobPosts.filter((post) => post.slug === slug);
  if (post.length === 0) {
    return null;
  }
  return post[0];
}

export default jobPosts;
