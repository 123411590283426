import React, { useEffect } from "react";

import { useSnackbar } from "react-simple-snackbar";

export default function Snackbar({ message, duration }) {
  const [openSnackbar, closeSnackbar] = useSnackbar({
    style: {
      fontSize: "1.25rem",
    },
  });
  const btnOpen = React.useRef(null);

  useEffect(() => {
    let timer = setTimeout(() => {
      btnOpen.current.click();
    }, 20);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="hidden">
      <button
        ref={btnOpen}
        onClick={() => openSnackbar(message, duration)}
      ></button>
      <button onClick={closeSnackbar}></button>
    </div>
  );
}
