import React, { useState, useContext } from "react";
import { Redirect } from "react-router-dom";
import PhoneInput from "react-phone-input-2";

import { AuthUserContext } from "../Session";
import { FirebaseContext } from "../Firebase";
import NotFound from "../NotFound";
import * as ROUTES from "../../constants/routes";
import { getPostByRole } from "../../data";

import "./index.css";

function FormPage(props) {
  const user = useContext(AuthUserContext);

  const { role } = props.match.params;
  const post = getPostByRole(role);
  if (!post) {
    return <NotFound />;
  }

  if (!user) {
    return <Redirect to={ROUTES.HOME} />;
  }
  return <Form user={user} role={role} post={post} />;
}

const submitStates = {
  NOT_SUBMITTED: 1,
  LOADING: 2,
  SUCCESS: 3,
  ERROR: 4,
};

function Form({ user, role, post }) {
  const { email, displayName } = user;
  let [name, setName] = useState(displayName);
  let [isNameInvalid, setIsNameInvalid] = useState(false);
  let [url, setUrl] = useState("");
  let [isUrlInvalid, setIsUrlInvalid] = useState(false);
  let [phone, setPhone] = useState("");
  let [isPhoneInvalid, setIsPhoneInvalid] = useState(false);
  let [yearsExp, setYearsExp] = useState("");
  let [isYearsExpInvalid, setIsYearsExpInvalid] = useState(false);

  const [submitStatus, setSubmitStatus] = useState(submitStates.NOT_SUBMITTED);

  const firebase = useContext(FirebaseContext);

  const handleNameChange = (evt) => {
    setName(evt.target.value);
    setIsNameInvalid(false);
    setSubmitStatus(submitStates.NOT_SUBMITTED);
  };

  const handleUrlChange = (evt) => {
    setUrl(evt.target.value);
    setIsUrlInvalid(false);
    setSubmitStatus(submitStates.NOT_SUBMITTED);
  };

  const handlePhoneChange = (phone) => {
    setPhone(phone);
    setIsPhoneInvalid(false);
    setSubmitStatus(submitStates.NOT_SUBMITTED);
  };

  const handleYearsExpChange = (yearsExp) => {
    setYearsExp(yearsExp);
    setIsYearsExpInvalid(false);
    setSubmitStatus(submitStates.NOT_SUBMITTED);
  };

  const validateInputs = () => {
    if (!validateName(name)) {
      setIsNameInvalid(true);
      return false;
    }
    if (!validatePhone(phone)) {
      setIsPhoneInvalid(true);
      return false;
    }
    if (post.showUrlField && !validateUrl(url)) {
      setIsUrlInvalid(true);
      return false;
    }
    if (post.yearsExpField.show && !validateYearsExp(yearsExp)) {
      setIsYearsExpInvalid(true);
      return false;
    }
    return true;
  };

  const submitForm = async () => {
    setSubmitStatus(submitStates.LOADING);
    const saveApplication = firebase.functions.httpsCallable("saveApplication");
    try {
      await saveApplication({
        name: capitalizeName(name),
        email,
        phone,
        url,
        role,
        yearsExp,
      });
    } catch (e) {
      setSubmitStatus(submitStates.ERROR);
      return;
    }
    setSubmitStatus(submitStates.SUCCESS);
    setTimeout(() => {
      firebase.doSignOut();
    }, 10000);
  };

  const handleSubmit = () => {
    if (!validateInputs()) {
      return;
    }
    submitForm();
  };

  function renderSubmitStatus() {
    switch (submitStatus) {
      case submitStates.NOT_SUBMITTED: {
        return "Apply";
      }
      case submitStates.LOADING: {
        return (
          <div className="flex items-center">
            <div className="loader inline-block ease-linear rounded-full border-2 border-gray-200 h-5 w-5"></div>
            <span className="ml-3">Saving...</span>
          </div>
        );
      }
      case submitStates.SUCCESS: {
        return "Saved";
      }
      case submitStates.ERROR: {
        return "Apply";
      }
      default: {
        throw new Error("Invalid submitState");
      }
    }
  }

  function renderForm() {
    return (
      <form className="-mt-20 p-8 shadow-lg rounded-lg bg-white">
        <p>
          {" "}
          Hey {getFirstName(user.displayName)}! Apply for the{" "}
          <span className="font-semibold">{post.title}</span> position.
        </p>
        <Label text="Full Name" />
        <input
          className="appearance-none border-2 border-blue-400 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
          type="text"
          minLength="4"
          maxLength="70"
          value={name}
          onChange={handleNameChange}
          disabled={submitStatus === submitStates.LOADING}
        />
        <Label text="Phone Number" />
        <PhoneInput
          country={"in"}
          onlyCountries={["in"]}
          value={phone}
          placeholder={"Phone Number"}
          countryCodeEditable={false}
          disableDropdown={true}
          inputProps={{
            name: "phone",
            required: true,
          }}
          inputClass={
            "appearance-none border-2 border-blue-400 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
          }
          containerClass={"mt-2"}
          onChange={handlePhoneChange}
          disabled={submitStatus === submitStates.LOADING}
        />
        {post.showUrlField && (
          <>
            {" "}
            <Label text="LinkedIn / GitHub / Resume / Personal Website URL" />
            <input
              className="appearance-none border-2 border-blue-400 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              type="url"
              value={url}
              placeholder="https://linkedin.com/in/username"
              onChange={handleUrlChange}
              disabled={submitStatus === submitStates.LOADING}
            />{" "}
          </>
        )}
        {post.yearsExpField.show && (
          <>
            <Label text="Experience (in years)" />
            <YearsExpDropdown
              value={yearsExp}
              start={post.yearsExpField.start}
              end={post.yearsExpField.end}
              onChange={handleYearsExpChange}
            />
          </>
        )}

        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold mt-6 py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
          onClick={handleSubmit}
          disabled={submitStatus === submitStates.LOADING}
        >
          {renderSubmitStatus()}
        </button>
        <InputErrorMessage
          isNameInvalid={isNameInvalid}
          isPhoneInvalid={isPhoneInvalid}
          isUrlInvalid={isUrlInvalid}
          isYearsExpInvalid={isYearsExpInvalid}
        />
        <SubmitErrorMessage show={submitStatus === submitStates.ERROR} />
        {post.explainResumeNotNeeded && (
          <div
            className="mt-6 bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3"
            role="alert"
          >
            <p className="font-bold">Resume not needed</p>
            <p className="text-sm">
              We don't need your resume as we assess applicants through coding
              tests, regardless of prior background.
            </p>
          </div>
        )}
      </form>
    );
  }

  function renderSuccess() {
    return (
      <div role="alert" className="mt-4">
        <div className="bg-green-600 text-white font-bold rounded-t px-4 py-2">
          Success
        </div>
        <div className="border border-t-0 border-green-400 rounded-b bg-green-100 px-4 py-3 text-green-700">
          <p>Your application is saved. Kindly check your email for further details. Thanks for applying to MountBlue!</p>
          <p>You'll be signed out now.</p>
        </div>
      </div>
    );
  }

  let content =
    submitStatus === submitStates.SUCCESS ? renderSuccess() : renderForm();
  return (
    <section className="flex flex-col justify-center max-w-lg p-6 mx-auto text-xl">
      {content}
    </section>
  );
}

function Label({ text }) {
  return (
    <label className="block text-gray-700 text-sm font-bold mt-6 mb-2">
      {text}
    </label>
  );
}

function InputErrorMessage({
  isNameInvalid,
  isPhoneInvalid,
  isUrlInvalid,
  isYearsExpInvalid,
}) {
  let message = "";
  if (isNameInvalid) {
    message = "Please enter a valid name";
  } else if (isPhoneInvalid) {
    message = "Please enter a valid phone number";
  } else if (isUrlInvalid) {
    message = "Please enter a valid personal URL";
  } else if (isYearsExpInvalid) {
    message = "Please choose your experience in years";
  } else {
    return <></>;
  }

  return (
    <p className="mt-2 bg-orange-100 px-2 py-2 w-full text-red-700">
      {message}
    </p>
  );
}

function SubmitErrorMessage({ show }) {
  return show ? (
    <p className="mt-2 bg-orange-100 px-2 py-2 w-full text-red-700 font-semibold">
      Oops... We ran into an error. Please try again.
    </p>
  ) : (
    <></>
  );
}

function validateName(name) {
  // What if Cher applies to MB? We'll keep minimum length to 4.
  return name.length >= 4;
}

function getFirstName(name) {
  return name.split(" ")[0];
}

function capitalizeName(name) {
  return name
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function validatePhone(n) {
  return cleanPhoneNumber(n).length === 10;
}

function cleanPhoneNumber(n) {
  return n.replace("91", "").replace(" ", "").replace("-", "");
}

// https://stackoverflow.com/a/49849482/817277
function validateUrl(url) {
  const res = url.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );
  return res !== null;
}

function validateYearsExp(yearsExp) {
  return yearsExp !== "";
}

function YearsExpDropdown({ value, onChange, start, end }) {
  const options = [];
  for (let i = start; i <= end; i++) {
    let years = i < end ? i : `${i}+`;
    let option = (
      <option value={years} key={years}>
        {years}
      </option>
    );
    options.push(option);
  }

  return (
    <select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="block appearance-none w-full border border-blue-400 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none cursor-pointer"
    >
      <option value="">Please choose an option</option>
      {options}
    </select>
  );
}

export default FormPage;
