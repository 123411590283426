import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/functions";
import * as Sentry from "@sentry/browser";
import { isProdEnv } from "../../utils";

const ENV = process.env;

var config = {
  apiKey: ENV.REACT_APP_FIREBASE_API_KEY,
  authDomain: ENV.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: ENV.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: ENV.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: ENV.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: ENV.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: ENV.REACT_APP_FIREBASE_APP_ID,
  measurementId: ENV.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    this.app = app.initializeApp(config);
    /* Helper */

    this.serverValue = this.app.database.ServerValue;
    this.emailAuthProvider = this.app.auth.EmailAuthProvider;

    /* Firebase APIs */
    this.auth = this.app.auth();
    this.db = this.app.database();
    this.storage = this.app.storage();
    this.functions = this.app.functions("asia-east2");

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
  }

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignOut = () => {
    if (ENV.REACT_APP_DONT_SIGN_OUT === "1") {
      return;
    }
    this.auth.signOut();
    clearInterval(this.tokenRefreshTimer);
    this.authToken = null;
    this.authUser = null;
  };

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.authUser = authUser;
        this.refreshTokenPeriodically(authUser);
        if (isProdEnv()) {
          Sentry.configureScope(function (scope) {
            scope.setUser({ email: authUser.email });
          });
        }
        next(authUser);
      } else {
        fallback();
      }
    });

  refreshTokenPeriodically = (authUser) => {
    const ONE_SECOND = 1000;
    const ONE_MINUTE = 60 * ONE_SECOND;
    let TEN_MINUTES = 10 * ONE_MINUTE;
    if (this.tokenRefreshTimer) {
      clearInterval(this.tokenRefreshTimer);
    }
    this.tokenRefreshTimer = setInterval(async () => {
      this.authToken = await authUser.getIdToken(true);
    }, TEN_MINUTES);
  };
}

export default Firebase;
