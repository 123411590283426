import React from "react";

import imageUrl from "./mountain-2.jpg";

export default function BannerImage() {
  return (
    <img
      className="h-40 sm:h-48 w-full object-cover object-center"
      src={imageUrl}
      alt="Blue Mountain banner"
    />
  );
}
