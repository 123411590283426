import React from "react";

export default function Footer() {
  return (
    <footer>
      <p className="pl-6 pr-6 py-8 text-center text-lg text-gray-800 bg-blue-100 ">
        Write to{" "}
        <a
          href="mailto:hello@mountblue.io"
          className="underline text-blue-600 hover:text-blue-700"
        >
          hello@mountblue.io
        </a>{" "}
        if you have any queries.
      </p>
    </footer>
  );
}
