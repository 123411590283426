import React from "react";

import { FirebaseContext } from "../Firebase";

import NotFound from "../NotFound";
import Post from "./Post";
import ApplyButton from "./ApplyButton";
import { getPostByRole } from "../../data";

export default function RoleDetail(props) {
  const firebase = React.useContext(FirebaseContext);
  window.fb = firebase;
  const [signInError, setSignInError] = React.useState(false);

  let { role } = props.match.params;
  if (role === "trainee") {
    role = "sde";
  }
  const post = getPostByRole(role);
  if (!post) {
    return <NotFound />;
  }
  if (!post.active) {
    return <RoleNotActive title={post.title} />;
  }

  async function handleApplyClick() {
    if (firebase.authUser) {
      props.history.push(`/${role}/apply`);
      return;
    }
    setSignInError(false);
    try {
      await firebase.doSignInWithGoogle();
    } catch (e) {
      setSignInError(true);
      return;
    }
    props.history.push(`/${role}/apply`);
  }

  const ApplyButtonWrapper = () => {
    return <ApplyButton onClick={handleApplyClick} showError={signInError} />;
  };

  return (
    <article className="max-w-2xl p-6 mx-auto text-lg sm:text-xl">
      <Post {...post} ApplyButton={ApplyButtonWrapper} />
    </article>
  );
}

function RoleNotActive({ title }) {
  return (
    <article className="max-w-2xl p-6 mx-auto text-lg sm:text-xl">
      Sorry, there are no openings for the {title} role as of now. Reach out to{" "}
      <a href="jobs@mountblue.io">hello@mountblue.io</a> if you have any
      queries.
    </article>
  );
}
