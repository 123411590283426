import React from "react";

export default function NotFound() {
  return (
    <section className="max-w-xl p-6 mx-auto text-xl text-center">
      <h2 className="text-5xl mb-6">404</h2>
      <p>
        Uh oh. We cannot find the page you requested. Write to{" "}
        <a
          href="mailto:hello@mountblue.io"
          className="underline text-blue-500 hover:text-blue-700"
        >
          hello@mountblue.io
        </a>{" "}
        if you have any queries.
      </p>
    </section>
  );
}
