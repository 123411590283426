import React from "react";
import ReactDOM from "react-dom";
import SnackbarProvider from "react-simple-snackbar";

import * as Sentry from "@sentry/browser";

import App from "./components/App";
import Firebase, { FirebaseContext } from "./components/Firebase";

import "./index.css";
import { isProdEnv } from "./utils";

if (isProdEnv()) {
  Sentry.init({
    dsn: "https://4a8aa33dcab845b0a40339706776f70e@sentry.io/4577617",
  });
}

ReactDOM.render(
  <SnackbarProvider>
    <FirebaseContext.Provider value={new Firebase()}>
      <App />
    </FirebaseContext.Provider>
  </SnackbarProvider>,
  document.getElementById("root")
);
